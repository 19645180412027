<template>
  <view-container name="settings">
    <view-header>
      <custom-header :router-back="back">
        <template v-slot:default>
          <svg-icon size="size24x24" :name="'icoArrowLeft'" />Coins storage
        </template>
        <template v-slot:last>
          <span class='start-storage__stars'>
            <svg-icon size="size16x16" name="icoCoin" />
            <span>{{balance}}</span>
          </span>
        </template>
      </custom-header>
    </view-header>
    <view-content v-if='shop.length'>
      <div class='start-storage__list'>
        <div v-for='item in shop' :key='item.id' class='start-storage__item'>
          <div class='start-storage__star'>
            <img :src='item.image_url' alt=''>
          </div>
          <div class='start-storage__info'>
            <span class='start-storage__count'>{{item.count_points}}</span>
            <span v-if='item.count_free_points' class='start-storage__free'>+{{item.count_free_points}} free</span>
          </div>
          <div class='start-storage__act'>
            <a-button @click="buy(item.id)" :disabled='isFetching > 0'>
              <Icon :size="20" type="money"/>
              {{item.price}}
            </a-button>
          </div>
        </div>
      </div>
    </view-content>
  </view-container>
</template>

<script>
import Icon from '@/components/Icon.vue'
import services from '../api/services';
import {mapGetters} from 'vuex'

export default {
  name: 'CoinsStorage',
  components: {Icon},
  data() {
    return {
      shop: [],
      isFetching: 0
    }
  },
  computed: {
    paymentBackUrl() {
      if (this.$route.query.back) {
        return `${location.origin}${this.$route.query.back}`
      } else {
        return `${location.origin}${this.$route.path}`
      }
    },
    ...mapGetters({
      'balance': 'balance'
    })
  },
  mounted() {
    this.isFetching++
    services.getShop().then(response => {
      this.shop = response.data;
    }).finally(() => {
      this.isFetching--
    });
  },
  methods: {
    back() {
      if (/\?order_id=.+/.test(window.location.search)) {
        this.$router.push({name: 'newcompetition.appearance.default'})
      } else {
        this.$goBack();
      }
    },
    buy(id) {
      this.isFetching++
      services.getPaymentForm(id, this.paymentBackUrl).then(response => {
        window.location = response.data.url
      }).catch(() => {
        this.isFetching--
      })
    }
  },
}
</script>

<style lang='css' scoped>
.start-storage__stars > span {
  font-size: 14px;
  line-height: 140%;
  color: #D8D8D8;
}
.start-storage__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -16px;
}
.start-storage__item {
  width: calc(50% - 8px);
  position: relative;
  background: #1D2130;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 22px 20px 12px;
}
.start-storage__item:nth-child(odd) {
  margin-right: 16px;
}
.start-storage__info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.start-storage__count {
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #fff;
}
.start-storage__free {
  font-weight: bold;
  font-size: 12px;
  color: #4285F4;
}
.start-storage__star {
  text-align: center;
  margin-bottom: 12px;
}
.start-storage__act {
  margin-top: 10px;
  text-align: center;
}
.start-storage__act > button {
  width: auto;
  margin: auto;
}
.start-storage__star > img {
  max-width: 100%;
}
.start-storage__free {
  margin-left: 4px;
}
</style>
